import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Icon, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				AceTennis – Kortunuzu Bugün Rezerve Edin
			</title>
			<meta name={"description"} content={"AceTennis'te en kaliteli tenis kortu kiralama hizmetlerini keşfedin. Bakımlı kortlarımız, maçlar, antrenmanlar ve dostluk oyunları için mükemmeldir. Üstün bir tenis deneyimi için hemen rezervasyon yapın!"} />
			<link rel={"shortcut icon"} href={"https://glowesbair.org/img/5738682.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glowesbair.org/img/5738682.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glowesbair.org/img/5738682.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" background="#e2e8f0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					AceTennis Hakkında Daha Fazla Bilgi Edinin
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					AceTennis, en kaliteli tenis kortu kiralama hizmetlerini sunmaya ve müşteri memnuniyetine büyük önem vermeye odaklanmıştır. Misyonumuz, amatörler, profesyonel sporcular veya etkinlik organizatörleri olsun, her seviyeden tenisçilere olağanüstü bir ortam sunmaktır.
					<br />
					<br />
					Ekibimiz tenise tutkuyla bağlıdır ve tesislerimizi en yüksek standartlarda tutmak için çalışır. Kortlarımızın her rezervasyon için mükemmel durumda olmasını sağlıyor ve deneyiminizi geliştirmek için çeşitli ek hizmetler sunuyoruz.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Rezerve Et
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://glowesbair.org/img/2.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					object-position="0% 70%"
					sm-max-height="500px"
					height="100%"
				/>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://glowesbair.org/img/4.jpg) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Tenis Kortlarımız
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
					Tenis kortlarımız, üstün bir oyun deneyimi sağlamak üzere tasarlanmıştır. Kort yüzeylerimiz profesyonelce bakımdan geçer, bu da sürekli top sekmesi ve mükemmel tutuş sağlar. Kortlarımız, resmi standartlara uygun yüksek kaliteli ağlar ve işaretlerle donatılmıştır.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 16px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Tenis Dersleri:
						</Span>
						{" "}deneyimli eğitmenler eşliğinde bireysel dersler alın.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 16px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ekipman Kiralama:{" "}
						</Span>
						kaliteli tenis raketleri, topları ve diğer ekipmanları kiralayın.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--primary"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 16px/27px --fontFamily-sans" color="#505257">
						<Span
							font="400 18px/27px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Etkilik Düzenleme:{" "}
						</Span>
						tenis turnuvaları ve topluluk etkinlikleri düzenleyin.
					</Text>
				</Box>
				<Text margin="0px 0px 45px 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
					Tenise dair tüm ihtiyaçlarınızı karşılayacak kapsamlı hizmetler sunmaya çalışıyoruz.
				</Text>
				<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					Kortunuzu Bugün Rezerve Edin!
				</Text>
				<Text margin="0px 0px 0 0px" font="normal 300 16px/1.5 --fontFamily-sans" color="#505257">
					AceTennis'te en iyi tenis kortu kiralama hizmetleri ve ek hizmetlerle yararlanın. Bu fırsatı kaçırmayın — şimdi kortunuzu rezerve edin ve üst düzey bir tenis deneyiminin tadını çıkarın.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://glowesbair.org/img/5.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
						max-height="500px"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://glowesbair.org/img/6.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Personelimiz, mükemmel müşteri hizmetleri ve destek sağlama konusunda eğitimlidir. Müşteri geri bildirimlerini memnuniyetle karşılıyor ve önerilerinizi dikkate alarak tekliflerimizi sürekli iyileştirmeye çalışıyoruz. Memnuniyetiniz, bizim en büyük önceliğimizdir.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});